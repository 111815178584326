export const appVersion             = "2.1.4";
export const appVariant             = "kisters-app";

// app settings, names, logo
export const privacyPolicyUrl       = "https://www.kisters.eu/privacy-policy/";
export const companyNamePart        = "KISTERS";
export const middleNamePart         = "";
export const appNamePart            = "PreciBal";
export const logoUrl                = "assets/logo.png";

// settings for fake data for debug purposes. Enabling debug button in App Settings
export const displayFakeDataOnly    = false;
export const fakeIdType             = "ios";  // "android" = MAC address, "ios" = UUID, "pwa" = other weird ID
export const fakeDataFromStart      = false;
export const fakeModuleValueUpdate  = false;
export const showDebugButton        = false;

// basic BLE stuff
export const connectionTimeoutS     = 40;
export const writeTimeoutS          = 10;
export const debounceTimeMs         = 1000;
export const advValueLittleEndian   = true;
export const charaValueLittleEndian = true;
export const maxStringWriteLength   = 40;
export const valueHistorySize       = 360;
export const chartStepsCount        = 10;
export const modulesServiceUuid     = "1c11c297-d9c3-4f10-9758-3ace53318914";
export const settingsServiceUuid    = "00a0818c-435f-4496-837b-c060ba350c67";
export const descriptorsUuids       = [
  '00002901-0000-1000-8000-00805f9b34fb',   // name
  '00002909-0000-1000-8000-00805f9b34fb',   // decimals
  '00002906-0000-1000-8000-00805f9b34fb',   // range
  '00002904-0000-1000-8000-00805f9b34fb',   // format
  '000029f0-0000-1000-8000-00805f9b34fb',   // unit
  '000029f1-0000-1000-8000-00805f9b34fb',   // icon
  // note Info descriptor 29f2 is not here as we wan to read it later when a Setting is opened
]
export const descName               = "2901";   // Characteristic User Description
export const descNameFull           = "00002901-0000-1000-8000-00805f9b34fb";
export const descDecimals           = "2909";   // Number of Digitals
export const descDecimalsFull       = "00002909-0000-1000-8000-00805f9b34fb";
export const descRange              = "2906";   // Valid Range
export const descRangeFull          = "00002906-0000-1000-8000-00805f9b34fb";
export const descFormat             = "2904";   // Characteristic Presentation Format
export const descFormatFull         = "00002904-0000-1000-8000-00805f9b34fb";
export const descUnit               = "29f0";   // custom - Unit of Measure
export const descUnitFull           = "000029f0-0000-1000-8000-00805f9b34fb";
export const descIcon               = "29f1";   // custom - Ionic Icon
export const descIconFull           = "000029f1-0000-1000-8000-00805f9b34fb";
export const descInfo               = "29f2";
export const descInfoFull           = "000029f2-0000-1000-8000-00805f9b34fb";

// OTA
export const otaServiceUuid         = "1d14d6ee-fd63-4fa1-bfa4-8f47b42119f0";
export const otaControlCharaUuid    = "f7bf3564-fb6d-4e53-88a4-5e37e0326063";
export const otaDataCharaUuid       = "984227f3-34fc-4045-a5d0-2c581f81a153";
export const otaAllowedExtensions   = ['gbl'];
export const otaMaxFileSize         = 1024 * 1024;
export const otaDefaultMtu          = 247;
export const otaMaxMtu              = 247;

// UART
export const uartServiceUuid        = "6e400001-b5a3-f393-e0a9-e50e24dcca9e";
export const uartRxWriteCharaUuid   = "6e400002-b5a3-f393-e0a9-e50e24dcca9e";
export const uartTxNotifyCharaUuid  = "6e400003-b5a3-f393-e0a9-e50e24dcca9e";

// logging
export const logRetentionDays       = 7;
export const toastDefaultDurationMs = 4000;
export const verboseLogs            = false;
